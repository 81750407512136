import React, { FC } from 'react';
/* constants */
import { MAX_STAR_COUNT } from '../constants';
/* styles */
import * as style from './style.module.sass';
/* svg */
import Fullsvg from '../svg/full.svg';
import Emptysvg from '../svg/empty.svg';

export type TCounselingFeedbackSatisfaction = {
  level: number;
};

/**
 * @module Services/Components/CounselingFeedbackSatisfaction
 */
const CounselingFeedbackSatisfaction: FC<TCounselingFeedbackSatisfaction> = ({ level }) => {
  return (
    <div className={style.counselingFeedbackSatisfaction}>
      {[...Array(MAX_STAR_COUNT)].map((_, i) => (
        <div key={i} className={style.counselingFeedbackSatisfaction__icon}>
          {i < level ? <Fullsvg /> : <Emptysvg />}
        </div>
      ))}
    </div>
  );
};

export default CounselingFeedbackSatisfaction;
