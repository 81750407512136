import React, { useState } from 'react';
import * as style from './style.module.sass';

const Alert = ({ alerts }) => {
  const [on, setOn] = useState(true);
  if (!on) return null;
  const alert = alerts?.[0]?.[1];
  if (!alert) return null;
  const { title } = alert;
  let { message } = alert;
  // NOTE: for devise
  if (!title && !message) message = alert;

  const offModal = () => setOn(false);
  const stopPropagation = (e) => e.stopPropagation();

  return (
    <div className={style.modal} onClick={offModal}>
      <div className={style.modal__wrap} onClick={stopPropagation}>
        <div className={style.modal__title}>{title}</div>
        <div className={style.modal__message}>{message}</div>
        <nav onClick={offModal} className={style.modal__button} data-testid='close-button'>
          閉じる
        </nav>
        <div onClick={offModal} className={style.modal__close}>
          ×
        </div>
      </div>
    </div>
  );
};

export default Alert;
