import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { reducer as formReducer } from 'redux-form';
import * as reducers from './ducks'; // import all reducers from ducks/index.js

const rootReducer = {
  ...reducers,
  form: formReducer,
};

export default function configureStore(initialState = {}) {
  const middlewares = [thunk];
  return createStore(combineReducers(rootReducer), initialState, applyMiddleware(...middlewares));
}
