import React from 'react';
import { Provider } from 'react-redux';
import configureStore from './state/store.es6';
import App from './views/App';

const Contact = (props) => {
  const store = configureStore({ default: { ...props } });

  return (
    <Provider {...{ store }}>
      <App />
    </Provider>
  );
};

export default Contact;
