import { combineReducers } from 'redux';
import types from './types.es6';

const contact = (state = {}, action) => {
  switch (action.type) {
    case types.SUCCEED_CONTACT:
      return {
        ...state,
        status: 'SUCCEED_CONTACT',
      };
    case types.FAILED_CONTACT:
      return {
        ...state,
        status: 'FAILED_CONTACT',
      };
    default:
      return state;
  }
};

const counseling = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const reducer = combineReducers({
  contact,
  counseling,
});

export default reducer;
