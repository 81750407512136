/**
 * Components/BadgeApplication
 * @package students/components
 */
import React, { useState } from 'react';
/* component */
import CancelFrom from '../from';
import Confirmation from '../confirmation';
import Completion from '../completion';
/* types */
import { TApplicant, TMode, TSeminars } from '../types';

/**
 * types
 */

type TNext = {
  device: string;
  seminars: TSeminars;
};
/**
 * @param TBadgeApplication
 * @returns
 */
const SeminarCancel = (props: TNext) => {
  const { device, seminars } = props;
  const [mode, setMode] = useState<TMode>('form');
  const [applicant, setApplicant] = useState<TApplicant>({
    reservation_number: 0,
    email: '',
    id: 0,
    seminar_schedule_id: 0,
  });
  return (
    <div>
      {mode === 'form' && (
        <CancelFrom device={device} setMode={setMode} setApplicant={setApplicant} applicant={applicant} />
      )}
      {mode === 'completion' && <Completion device={device} />}
      {mode === 'confirm' && (
        <Confirmation
          device={device}
          setMode={setMode}
          setApplicant={setApplicant}
          applicant={applicant}
          seminars={seminars}
        />
      )}
    </div>
  );
};

export default SeminarCancel;
