import React from 'react';
import { Field } from 'redux-form';

// redux-form の Field 用コンポーネント（プライバシーポリシーのチェックボックス以外用）
const RenderField = ({ label, input, meta: { touched, error }, formElement: FormElement, required, ...rest }) => {
  const notifyError = touched && !!error;
  return (
    <p className={`c-form-field${notifyError ? '--error' : ''} p-contact-article__field`}>
      <dt>
        <span className={`p-contact-article__label--${required ? 'required' : 'optional'}`}>{label}</span>
        {notifyError && <em>{error}</em>}
      </dt>
      <dd className='p-contact-article__formElement'>
        <FormElement {...input} {...rest} />
      </dd>
    </p>
  );
};

// redux-form の Field 用コンポーネント（プライバシーポリシーのチェックボックス専用）
const RenderPrivacyPolicyCheckbox = ({ input: { value, onChange }, meta: { dirty, error } }) => {
  const notifyError = dirty && !!error;
  return (
    <p>
      <label className='c-form-checkboxLabel p-contact-article__label--policy' htmlFor='privacy-policy'>
        <input
          className='c-form-checkbox'
          type='checkbox'
          name='check'
          checked={!!value}
          {...{ onChange }}
          id='privacy-policy'
        />
        <i className='c-form-checkboxIcon' name='check' />
        <a href='/privacy_policy' target='_blank' className='u-link'>
          プライバシーポリシー
        </a>
        に同意する
      </label>
      {notifyError && <em>{error}</em>}
    </p>
  );
};

const Template = ({ valid, handleSubmit, submitting, status }) => (
  <>
    <form onSubmit={handleSubmit} className='c-form p-contact-article__form'>
      <dl>
        <Field
          component={RenderField}
          formElement='input'
          label='お名前'
          name='name'
          placeholder='お名前を入力してください'
          type='text'
          className='c-form-text'
          required
        />
        <Field
          component={RenderField}
          formElement='input'
          label='メールアドレス'
          name='email'
          placeholder='メールアドレスを入力してください'
          type='text'
          className='c-form-text'
          required
        />
        <Field
          component={RenderField}
          formElement='input'
          label='件名'
          name='subject'
          placeholder='件名を入力してください'
          type='text'
          className='c-form-text'
        />
        <Field
          component={RenderField}
          formElement='textarea'
          label='お問い合わせ内容'
          name='contact'
          placeholder='お問い合わせ内容を入力してください'
          type='text'
          className='c-form-textArea'
          required
        />
        <Field component={RenderPrivacyPolicyCheckbox} name='check' />
      </dl>
      <button className='p-contact-article__button--mail' disabled={!valid || submitting}>
        送信する
      </button>
    </form>

    {status === 'FAILED_CONTACT' && (
      <p className='p-contact-article__error'>お問い合わせに失敗しました。お手数ですが、再度お試しください。</p>
    )}
  </>
);

export default Template;
