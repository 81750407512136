import React from 'react';
import { Route } from 'react-router';
import Timetable from './containers/Timetable.es6';
import Confirm from './containers/Confirm.es6';
import Complete from './containers/Complete.es6';

export default () => (
  <div>
    <Timetable />
    <Route exact path='/schedules/trial/confirm' component={Confirm} />
    <Route exact path='/schedules/trial/complete' component={Complete} />
  </div>
);
