import request from 'superagent';
import types from './types.es6';
import { CURRICULUM_ID } from './constants.es6';

require('superagent-rails-csrf')(request);

export const fetch = () => {
  return (dispatch) => {
    request
      .get('/lessons/schedules.json')
      .query({ course: CURRICULUM_ID })
      .end((error, response) => {
        return dispatch(insertSchedules(response.body));
      });
  };
};

const insertSchedules = (response) => ({
  type: types.INSERT_SCHEDULES,
  ...response,
});

const insertConfirm = (period) => ({
  type: types.INSERT_CONFIRM,
  period,
});

export const insertResult = (value) => ({
  type: types.INSERT_RESULT,
  value,
});

export const reserve = (email, url) => {
  return (dispatch) => {
    request
      .post(url)
      .send({ student: { email } })
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, { body }) => {
        return dispatch(
          insertResult({
            ...body,
            method: 'email',
          }),
        );
      });
  };
};

export const preReserve = (method, datetime, email, referrer) => {
  return (dispatch) => {
    request
      .post('/lessons/api/reserve')
      .send({ method, datetime, curriculum_id: CURRICULUM_ID, referrer })
      .setCsrfToken()
      .end((error, { body }) => {
        const { url, status } = body;

        if (status && method === 'email') return dispatch(reserve(email, url));

        return dispatch(insertResult(body));
      });
  };
};

export default {
  fetch,
  reserve,
  preReserve,
  insertConfirm,
};
