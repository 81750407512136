import request from 'superagent';
import types from './types.es6';

require('superagent-rails-csrf')(request);

export const succeedContact = () => ({ type: types.SUCCEED_CONTACT });
export const failedContact = () => ({ type: types.FAILED_CONTACT });

export const contact =
  ({ name, email, subject, contact }) =>
  (dispatch) => {
    request
      .post('/contact')
      .send({ name, contact: { email, subject, contact } })
      .setCsrfToken()
      .end((error, response) => {
        if (response.ok) {
          window.location.href = '/';
          dispatch(succeedContact());
        } else {
          dispatch(failedContact());
        }
      });
  };

export default {
  contact,
};
