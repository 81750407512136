import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import * as reducers from './ducks'; // import all reducers from ducks/index.js

const rootReducer = {
  ...reducers,
  routing: routerReducer,
  form: formReducer,
};

export default function configureStore(initialState = {}, history) {
  const middlewares = [thunk, routerMiddleware(history)];
  return createStore(combineReducers(rootReducer), initialState, applyMiddleware(...middlewares));
}
