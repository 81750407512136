import React, { useState, SetStateAction, Dispatch } from 'react';
import * as stylePC from './style.module.sass';
import * as styleSP from './style.sp.module.sass';
import { TApplicant, TMode } from '../types';
import validator from 'validator'; // @see https://www.npmjs.com/package/validator
import { postInput } from '../api';

type TNext = {
  device: string;
  setMode: Dispatch<SetStateAction<TMode>>;
  setApplicant: Dispatch<SetStateAction<TApplicant>>;
  applicant: TApplicant;
};

const CancelFrom: React.FC<TNext> = (props: TNext) => {
  const { device, setMode, setApplicant, applicant } = props;

  const [isChecked, setIsChecked] = useState({
    enteredReservationNumber: false,
    enteredEmail: false,
    noReservation: false,
  });
  const [isError, setIsError] = useState({
    reservationError: false,
    emailError: false,
  });
  // メールを入力
  const handleCheckEmail = (event: any) => {
    setApplicant({ ...applicant, email: event.target.value });
    setIsChecked({ ...isChecked, enteredEmail: true });
    if (!event.target.value.length) return setIsChecked({ ...isChecked, enteredEmail: false });
  };
  const handleCheckReservation = (event: any) => {
    setApplicant({ ...applicant, reservation_number: event.target.value });
    setIsChecked({ ...isChecked, enteredReservationNumber: true });
    if (!event.target.value.length) return setIsChecked({ ...isChecked, enteredReservationNumber: false });
  };

  const isNotEntered = isChecked.enteredReservationNumber && isChecked.enteredEmail;
  const handleClickNext = async () => {
    // @ts-expect-error
    const reservationValidator = !validator.isNumeric(applicant.reservation_number, { max: 4 });
    const emailValidator = !validator.isEmail(applicant.email.trim());
    setIsError({ ...isError, emailError: emailValidator, reservationError: reservationValidator });
    const res = await postInput(applicant);
    if (!res.data) return setIsChecked({ ...isChecked, noReservation: true });
    setApplicant({ ...applicant, id: res.id, seminar_schedule_id: res.seminar_schedule_id });
    if (!res.data || !isChecked.enteredEmail || !isChecked.enteredReservationNumber) return;
    setMode('confirm');
  };
  const style = device === 'sp' ? styleSP : stylePC;
  return (
    <>
      <h1 className={style.heading}>ご予約のキャンセル</h1>
      {isChecked.noReservation && (
        <p className={style.errorText}>
          予約番号と予約時のメールアドレスが一致しません
          <br />
          再度お試しください
        </p>
      )}
      <div className={style.container}>
        <fieldset className={style.field}>
          <div className={style.item}>
            予約番号<span className={style.required}>必須</span>
          </div>
          <div className={style.data}>
            <p className={style.text}>
              予約番号を入力してください
              <br />
              予約番号は予約完了メールに記載があります
            </p>
            <input
              type='text'
              id='reservation_number'
              name='reservation_number'
              maxLength={4}
              onChange={handleCheckReservation}
              className={`${style.form__text} ${isChecked.noReservation && style.isError}  ${
                !isError.reservationError ? '' : style.isError
              }`}
              placeholder='0000'
            />
          </div>
        </fieldset>
        <fieldset className={style.field}>
          <div className={style.item}>
            予約時のメールアドレス<span className={style.required}>必須</span>
          </div>
          <div className={style.data}>
            <p className={style.text}>予約時のメールアドレスを入力してください</p>
            <input
              className={`${style.form__text} ${isChecked.noReservation && style.isError} ${
                !isError.emailError ? '' : style.isError
              }`}
              type='mail'
              id='email'
              name='email'
              maxLength={300}
              onChange={handleCheckEmail}
              placeholder='abcdefg@codecamp.jp'
              value={applicant.email}
            />
          </div>
        </fieldset>
        <div className={style.nav}>
          {isNotEntered ? (
            <button id='submitBtn' name='regist' onClick={handleClickNext} type='submit' className={style.button}>
              キャンセル内容を確認する
            </button>
          ) : (
            <button disabled className={style.button}>
              キャンセル内容を確認する
            </button>
          )}
        </div>
      </div>
    </>
  );
};
export default CancelFrom;
