import React from 'react';
import * as stylePC from './style.module.sass';
import * as styleSP from './style.sp.module.sass';

type TNext = {
  device: string;
};

const Completion: React.FC<TNext> = (props: TNext) => {
  const { device } = props;
  const style = device === 'sp' ? styleSP : stylePC;
  return (
    <>
      <h1 className={style.heading}>キャンセル手続き完了</h1>
      <div className={style.container}>
        <a href='/next#entry' target='_blank' className={style.button}>
          他の日程を予約する
        </a>
      </div>
    </>
  );
};
export default Completion;
